"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findAllIn, findIn, closest} from "@elements/dom-utils";

const selectors = {
    base: '.js-center-active-tab',
    tab: '.js-center-active-tab__tab'
};

export function init() {
    onFind(selectors.base, function (element) {
        let activeTab = findIn('.active', element);
        let tab = findIn(selectors.tab, element);
        let tabs = findAllIn(selectors.tab, element);

        center(tab, activeTab);

        tabs.forEach(function(tabItem) {
            on('click', function(e) {
                center(tab, e.target)
            }, tabItem);
        });
    });

}
function center(navTab, tab) {
    if(tab) {
        let parentUl = closest(selectors.base,navTab),
            childPos = tab.offsetLeft,
            parentScroll = tab.parentNode.scrollLeft,
            offset = childPos + parentScroll - parentUl.offsetWidth/2;

        parentUl.scrollTo({
            left: offset,
            behavior: 'smooth'
        });
    }
}

